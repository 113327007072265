import {authenticationReducer} from './slices'
import {checkoutReducer} from './slices/checkout'
import {combineReducers} from '@reduxjs/toolkit'
import {packagesReducer} from './slices/packages'
import {profileReducer} from './slices/profile'
import {storesReducer} from './slices/stores'

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  packages: packagesReducer,
  profile: profileReducer,
  stores: storesReducer,
  checkout: checkoutReducer,
})

export default rootReducer
