import axios from 'axios'

let baseURL = import.meta.env.VITE_REACT_APP_BACKEND_URL

// if (import.meta.env.MODE === 'development') {
//   baseURL = import.meta.env.VITE_REACT_APP_BACKEND_URL as string
// } else if (import.meta.env.MODE === 'production') {
//   baseURL = import.meta.env.VITE_REACT_APP_BACKEND_URL
// }

const http = axios.create({
  baseURL: baseURL,
})

export default http

export function setBearerToken(token: string | null) {
  if (token === null) delete http.defaults.headers.common['Authorization']
  else http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
