// MuiTableCell.ts
import {Components} from '@mui/material'

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      width: 'auto',
      paddingLeft: '10px',
      paddingTop: '25.5px',
      paddingBottom: '25.5px',
      paddingRight: 0,
      whiteSpace: 'nowrap',
    },
  },
}

export default MuiTableCell
