import {Components} from '@mui/material'
import {muiDisabled} from './selectors'
import palette from '../palette'

const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      borderRadius: '0px',
      textTransform: 'none',
    },
  },

  defaultProps: {
    disableElevation: true,
    variant: 'contained',
  },

  variants: [
    {
      props: {variant: 'contained', color: 'primary'},
      style: {
        '&:hover': {
          backgroundColor: palette.primary.dark,
          boxShadow: 'none',
        },
        [muiDisabled]: {
          backgroundColor: palette.primary.light,
          color: palette.common.white,
        },
      },
    },
    {
      props: {variant: 'selectedFile'},
      style: {
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: 'bold',
        backgroundColor: palette.common.skyBlue,
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.common.skyBlue,
        },
      },
    },

    {
      props: {variant: 'selectedFilter'},
      style: {
        height: '38px',
        padding: '10px 15px 10px 15px',
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: 'normal',
        backgroundColor: palette.common.primary,
        color: palette.common.white,
        '&:hover': {
          backgroundColor: palette.common.primary,
        },
      },
    },
    {
      props: {variant: 'filterButton'},
      style: {
        height: '56px',
        padding: '20px 30px 20px 30px',
        fontSize: '16px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 800,
        backgroundColor: palette.common.skyBlue,
        color: palette.common.primary,
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: palette.common.skyBlue,
        },
      },
    },
    {
      props: {variant: 'filterButtonSelected'},
      style: {
        height: '56px',
        padding: '20px 30px 20px 30px',
        fontSize: '16px',
        lineHeight: '16px',
        fontStyle: 'normal',
        fontWeight: 800,
        backgroundColor: palette.common.primary,
        color: palette.common.white,
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: palette.common.primary,
        },
      },
    },
    {
      props: {variant: 'clearFilter'},
      style: {
        height: '39px',
        fontSize: '13px',
        lineHeight: '26px',
        paddingLeft: '26px',
        paddingRight: '26px',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 'bold',
        backgroundColor: palette.common.skyBlue,
        color: palette.primary.main,
        ['&:hover']: {
          backgroundColor: palette.common.skyBlue,
        },
      },
    },
  ],
}

export default MuiButton
