// typography.ts

import {TypographyOptions} from '@mui/material/styles/createTypography'

const typography: TypographyOptions = {
  fontFamily: 'Nexa Trial, sans-serif',
  fontWeightRegular: 400,
  fontWeightBold: 700,
}

export default typography
