import {createAction, createSlice} from '@reduxjs/toolkit'

import {GetStoresResponse} from './types'

export interface StoresState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  stores: GetStoresResponse
}

const initialState: StoresState = {
  status: 'idle',
  stores: {
    page: 0,
    totalPages: 0,
    itemsPerPage: 0,
    results: [],
  },
}

export const getStoresPending = createAction('stores/getStores/pending')
export const getStoresFulfilled = createAction<GetStoresResponse>(
  'stores/getStores/fulfilled',
)
export const getStoresRejected = createAction('stores/getStores/rejected')

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStoresPending, state => {
        state.status = 'loading'
      })
      .addCase(getStoresFulfilled, (state, action) => {
        state.status = 'succeeded'
        state.stores = action.payload
      })
      .addCase(getStoresRejected, state => {
        state.status = 'failed'
      })
  },
})

export default storesSlice.reducer
