import {createAction, createSlice} from '@reduxjs/toolkit'

import {LoginResponse} from './types'
import auth from '../../../../utils/auth'

export interface AuthenticationState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'

  changePasswordStatus: 'idle' | 'loading' | 'succeeded' | 'failed'

  recoverPasswordStatus: 'idle' | 'loading' | 'succeeded' | 'failed'

  getOtpStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: AuthenticationState = {
  status: 'idle',
  changePasswordStatus: 'idle',
  recoverPasswordStatus: 'idle',
  getOtpStatus: 'idle',
}

export const loginPending = createAction('authentication/login/pending')
export const loginFulfilled = createAction<LoginResponse>(
  'authentication/login/fulfilled',
)
export const loginRejected = createAction('authentication/login/rejected')

export const registerPending = createAction('authentication/register/pending')
export const registerFulfilled = createAction<LoginResponse>(
  'authentication/register/fulfilled',
)
export const registerRejected = createAction('authentication/register/rejected')

export const changePasswordPending = createAction(
  'authentication/changePassword/pending',
)
export const changePasswordFulfilled = createAction(
  'authentication/changePassword/fulfilled',
)
export const changePasswordRejected = createAction(
  'authentication/changePassword/rejected',
)

export const getOtpPending = createAction('authentication/getOtp/pending')
export const getOtpFulfilled = createAction('authentication/getOtp/fulfilled')
export const getOtpRejected = createAction('authentication/getOtp/rejected')

export const recoverPasswordPending = createAction(
  'authentication/recoverPassword/pending',
)
export const recoverPasswordFulfilled = createAction(
  'authentication/recoverPassword/fulfilled',
)
export const recoverPasswordRejected = createAction(
  'authentication/recoverPassword/rejected',
)

export const logout = createAction('auth/logout', () => {
  auth.logout()
  return {payload: null}
})

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loginPending, state => {
        state.status = 'loading'
      })
      .addCase(loginFulfilled, (state, _) => {
        state.status = 'succeeded'
      })
      .addCase(loginRejected, state => {
        state.status = 'failed'
      })
      .addCase(registerPending, state => {
        state.status = 'loading'
      })
      .addCase(registerFulfilled, (state, _) => {
        state.status = 'succeeded'
      })
      .addCase(registerRejected, state => {
        state.status = 'failed'
      })
      .addCase(changePasswordPending, state => {
        state.changePasswordStatus = 'loading'
      })
      .addCase(changePasswordFulfilled, state => {
        state.changePasswordStatus = 'succeeded'
      })
      .addCase(changePasswordRejected, state => {
        state.changePasswordStatus = 'failed'
      })

      .addCase(getOtpPending, state => {
        state.getOtpStatus = 'loading'
      })
      .addCase(getOtpFulfilled, state => {
        state.getOtpStatus = 'succeeded'
      })
      .addCase(getOtpRejected, state => {
        state.getOtpStatus = 'failed'
      })

      .addCase(recoverPasswordPending, state => {
        state.recoverPasswordStatus = 'loading'
      })
      .addCase(recoverPasswordFulfilled, state => {
        state.recoverPasswordStatus = 'succeeded'
      })
      .addCase(recoverPasswordRejected, state => {
        state.recoverPasswordStatus = 'failed'
      })
  },
})

export default authenticationSlice.reducer
