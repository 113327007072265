import {MyPoBox, Profile, UpdateRtnResponse} from './types'
import {createAction, createSlice} from '@reduxjs/toolkit'

export interface ProfileState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  profile: Profile | null

  myPoBoxStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  myPoBox: MyPoBox | null

  updateRtnStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: ProfileState = {
  status: 'idle',
  profile: null,

  myPoBoxStatus: 'idle',
  myPoBox: null,

  updateRtnStatus: 'idle',
}

export const getProfilePending = createAction('profile/getProfile/pending')
export const getProfileFulfilled = createAction<Profile>(
  'profile/getProfile/fulfilled',
)
export const getProfileRejected = createAction('profile/getProfile/rejected')

export const getMyPoBoxPending = createAction('profile/getMyPoBox/pending')
export const getMyPoBoxFulfilled = createAction<MyPoBox>(
  'profile/getMyPoBox/fulfilled',
)
export const getMyPoBoxRejected = createAction('profile/getMyPoBox/rejected')

export const updateRtnPending = createAction('profile/updateRtn/pending')
export const updateRtnulfilled = createAction<UpdateRtnResponse>(
  'profile/updateRtn/fulfilled',
)
export const updateRtnRejected = createAction('profile/updateRtn/rejected')

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProfilePending, state => {
        state.status = 'loading'
      })
      .addCase(getProfileFulfilled, (state, action) => {
        state.status = 'succeeded'
        state.profile = action.payload
      })
      .addCase(getProfileRejected, state => {
        state.status = 'failed'
      })

      .addCase(getMyPoBoxPending, state => {
        state.myPoBoxStatus = 'loading'
      })
      .addCase(getMyPoBoxFulfilled, (state, action) => {
        state.myPoBoxStatus = 'succeeded'
        state.myPoBox = action.payload
      })
      .addCase(getMyPoBoxRejected, state => {
        state.myPoBoxStatus = 'failed'
      })

      .addCase(updateRtnPending, state => {
        state.updateRtnStatus = 'loading'
      })
      .addCase(updateRtnulfilled, (state, action) => {
        state.updateRtnStatus = 'succeeded'
        if (state.profile) {
          state.profile.civilId = action.payload.civilId
        }
      })
      .addCase(updateRtnRejected, state => {
        state.updateRtnStatus = 'failed'
      })
  },
})

export default profileSlice.reducer
