import {PaletteMode, SimplePaletteColorOptions} from '@mui/material'

const mainColors = {
  primary: '#1A375F',
  secondary: '#ffe800',
  tertiary: '#e1251b',
  white: '#FFFFFF',
  black: '#000000',
  purple: '#6742FE',
  lightPurple: '#DEd7FF',
  blue: '#0832D5',
  lightBlue: '#D2DEFF',
  skyBlue: '#F3F6FF',
  yellow: '#C79E1F',
  lightYellow: '#F9F2C1',
  orange: '#D86607',
  lightOrange: '#f8DDC0',
  red: '#B94341',
  lightRed: '#f7C9c7',
  green: '#61B74d',
  lightGreen: '#DCf1D7',
  darkGray: '#303030',
  lightGray: '#E5E5E5',
}

const mode: PaletteMode = 'light'

const common = {
  black: mainColors.black,
  white: mainColors.white,
  purple: mainColors.purple,
  lightPurple: mainColors.lightPurple,
  blue: mainColors.blue,
  lightBlue: mainColors.lightBlue,
  skyBlue: mainColors.skyBlue,
  yellow: mainColors.yellow,
  lightYellow: mainColors.lightYellow,
  orange: mainColors.orange,
  lightOrange: mainColors.lightOrange,
  red: mainColors.red,
  lightRed: mainColors.lightRed,
  green: mainColors.green,
  lightGreen: mainColors.lightGreen,
  darkGray: mainColors.darkGray,
  lightGray: mainColors.lightGray,
  primary: mainColors.primary,
  secondary: mainColors.secondary,
  tertiary: mainColors.tertiary,
}

const primary: SimplePaletteColorOptions = {
  main: mainColors.primary,
}

const secondary: SimplePaletteColorOptions = {
  main: mainColors.secondary,
}

const tertiary: SimplePaletteColorOptions = {
  main: mainColors.tertiary,
}

const palette = {
  mode,
  common,
  primary,
  secondary,
  tertiary,
  // bg,
  // text,
  // border,
  // error,
  // warning,
}

export default palette
