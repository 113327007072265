// checkoutSlice.ts
import {createAction, createSlice} from '@reduxjs/toolkit'

interface CheckoutState {
  processPaymentStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  createInvoiceStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  createOrderNumberStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
  transactionId: string | null
}

const initialState: CheckoutState = {
  processPaymentStatus: 'idle',
  createInvoiceStatus: 'idle',
  createOrderNumberStatus: 'idle',
  transactionId: null,
}

export const processPaymentPending = createAction(
  'checkout/processPayment/pending',
)
export const processPaymentFulfilled = createAction<any>(
  'checkout/processPayment/fulfilled',
)
export const processPaymentRejected = createAction(
  'checkout/processPayment/rejected',
)

export const createInvoicePending = createAction(
  'checkout/createInvoice/pending',
)
export const createInvoiceFulfilled = createAction<any>(
  'checkout/createInvoice/fulfilled',
)
export const createInvoiceRejected = createAction(
  'checkout/createInvoice/rejected',
)

export const createOrderNumberPending = createAction(
  'checkout/createOrderNumber/pending',
)
export const createOrderNumberFulfilled = createAction<any>(
  'checkout/createOrderNumber/fulfilled',
)
export const createOrderNumberRejected = createAction(
  'checkout/createOrderNumber/rejected',
)

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(processPaymentPending, state => {
        state.processPaymentStatus = 'loading'
      })
      .addCase(processPaymentFulfilled, (state, action) => {
        state.processPaymentStatus = 'succeeded'
        state.transactionId = action.payload.transaction_id
      })
      .addCase(processPaymentRejected, (state, _) => {
        state.processPaymentStatus = 'failed'
      })

      .addCase(createInvoicePending, state => {
        state.createInvoiceStatus = 'loading'
      })
      .addCase(createInvoiceFulfilled, state => {
        state.createInvoiceStatus = 'succeeded'
      })
      .addCase(createInvoiceRejected, (state, _) => {
        state.createInvoiceStatus = 'failed'
      })

      .addCase(createOrderNumberPending, state => {
        state.createOrderNumberStatus = 'loading'
      })
      .addCase(createOrderNumberFulfilled, state => {
        state.createOrderNumberStatus = 'succeeded'
      })
      .addCase(createOrderNumberRejected, (state, _) => {
        state.createOrderNumberStatus = 'failed'
      })
  },
})

export default checkoutSlice.reducer
