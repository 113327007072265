import {
  DecodedUser,
  LoginResponse,
} from '../src/store/slices/authentication/types'
import http, {setBearerToken} from './http'

import decode from 'jwt-decode'

const USER_DATA = 'auth_user_data'
const ACCESS_TOKEN = 'auth_access'
const REFRESH_TOKEN = 'auth_refresh'

class AuthenticationManager {
  constructor() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    if (accessToken) setBearerToken(accessToken)
    else this.logout()

    http.interceptors.response.use(undefined, async error => {
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        auth.refreshAccessToken()

        error.config.__isRetryRequest = true
        return http(error.config)
      }

      throw error
    })
  }

  login(loginResponse: LoginResponse) {
    const {accessToken, refreshToken} = loginResponse

    const user = decode(accessToken)

    this.user = user as DecodedUser
    this.accessToken = accessToken
    this.refreshToken = refreshToken
  }

  logout() {
    this.user = null
    this.accessToken = null
    this.refreshToken = null!
  }

  set user(user: DecodedUser | null) {
    if (user) localStorage.setItem(USER_DATA, JSON.stringify(user))
    else localStorage.removeItem(USER_DATA)
  }

  private set accessToken(token: string | null) {
    if (token) localStorage.setItem(ACCESS_TOKEN, token)
    else localStorage.removeItem(ACCESS_TOKEN)

    setBearerToken(token)
  }

  get isLoggedIn() {
    return !!localStorage.getItem(ACCESS_TOKEN)
  }

  private _refreshToken: string | null = null

  set refreshToken(token: string | null) {
    if (token) localStorage.setItem(REFRESH_TOKEN, token)
    else localStorage.removeItem(REFRESH_TOKEN)
    this._refreshToken = token
  }

  get refreshToken(): string | null {
    return this._refreshToken
  }

  async refreshAccessToken() {
    try {
      const response = await http.post('/tokens/refresh', {
        refreshToken: this.refreshToken,
      })

      this.login(response.data) // You can use your existing login method to store the new tokens
    } catch (error) {
      console.error('Failed to refresh token', error)
      this.logout() // if the refresh fails, logout the user
    }
  }
}

const auth = new AuthenticationManager()
export default auth
