import {Components} from '@mui/material'
import MuiButton from './MuiButton'
import MuiTableCell from './MuiTableCell'

const components: Components = {
  MuiButton,
  MuiTableCell,
}

export default components
