import {Box, BoxProps, CircularProgress} from '@mui/material'

export default function Spinner(props: BoxProps) {
  return (
    <Box
      display="flex"
      height="100vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <CircularProgress size={150} />
    </Box>
  )
}
