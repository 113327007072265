import './config/i18n'
import '../public/fonts/fonts.css'

import * as React from 'react'
import * as Sentry from '@sentry/react'

import {CssBaseline} from '@mui/material'
import {HashRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom'
import Spinner from './components/Spinner'
import {ThemeProvider} from '@mui/material'
import store from './store/store'
import theme from '../src/config/theme'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://6d3ea070e1894f3fb65ea28454e2af96@o4505564755525632.ingest.sentry.io/4505564766928896',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://portal.gbox.hn/'],
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const App = React.lazy(() => import('./App'))

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <HashRouter>
          <React.Suspense fallback={<Spinner />}>
            <App />
          </React.Suspense>
        </HashRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
