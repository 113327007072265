import i18n, {ReactOptions} from 'i18next'

import Backend from 'i18next-http-backend'
import {DetectorOptions} from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

const detectionOptions: DetectorOptions = {}

const reactOptions: ReactOptions = {}

const fallbackLng = (code: string): string[] => {
  if (!code || code !== ('es' || 'en')) return ['es']
  else {
    return ['es']
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: detectionOptions,
    react: reactOptions,

    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
    returnEmptyString: false,
  })

export default i18n
