import {
  Charges,
  GetPackagesResponse,
  PackageAttachments,
  PackageDetail,
} from './types'
import {createAction, createSlice} from '@reduxjs/toolkit'

export interface PackagesState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  packages: GetPackagesResponse

  packageDetail: PackageDetail | null
  packageDetailStatus: 'idle' | 'loading' | 'succeeded' | 'failed'

  packageAttachments: PackageAttachments[] | null
  packageAttachmentsStatus: 'idle' | 'loading' | 'succeeded' | 'failed'

  charges: Charges | null
  chargesStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: PackagesState = {
  status: 'idle',
  packages: {
    page: 0,
    totalPages: 0,
    itemsPerPage: 0,
    results: [],
  },
  packageDetail: null,
  packageDetailStatus: 'idle',

  packageAttachments: null,
  packageAttachmentsStatus: 'idle',

  charges: null,
  chargesStatus: 'idle',
}

export const getPackagesPending = createAction('packages/getPackages/pending')
export const getPackagesFulfilled = createAction<GetPackagesResponse>(
  'packages/getPackages/fulfilled',
)
export const getPackagesRejected = createAction('packages/getPackages/rejected')

export const getPackageDetailPending = createAction(
  'packages/getPackageDetail/pending',
)
export const getPackageDetailFulfilled = createAction<PackageDetail>(
  'packages/getPackageDetail/fulfilled',
)
export const getPackageDetailRejected = createAction(
  'packages/getPackageDetail/rejected',
)

export const getPackageAttachmentsPending = createAction(
  'packages/getPackageAttachments/pending',
)

export const getPackageAttachmentsFulfilled = createAction<
  PackageAttachments[]
>('packages/getPackageAttachments/fulfilled')
export const getPackageAttachmentsRejected = createAction(
  'packages/getPackageAttachments/rejected',
)

export const getChargesPending = createAction('packages/getCharges/pending')
export const getChargesFulfilled = createAction<Charges>(
  'packages/getCharges/fulfilled',
)
export const getChargesRejected = createAction('packages/getCharges/rejected')

export const packagesSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPackagesPending, state => {
        state.status = 'loading'
      })
      .addCase(getPackagesFulfilled, (state, action) => {
        state.status = 'succeeded'
        state.packages = action.payload
      })
      .addCase(getPackagesRejected, state => {
        state.status = 'failed'
      })

      .addCase(getPackageDetailPending, state => {
        state.packageDetailStatus = 'loading'
      })
      .addCase(getPackageDetailFulfilled, (state, action) => {
        state.packageDetailStatus = 'succeeded'
        state.packageDetail = action.payload
      })
      .addCase(getPackageDetailRejected, state => {
        state.packageDetailStatus = 'failed'
      })

      .addCase(getPackageAttachmentsPending, state => {
        state.packageAttachmentsStatus = 'loading'
      })
      .addCase(getPackageAttachmentsFulfilled, (state, action) => {
        state.packageAttachmentsStatus = 'succeeded'
        state.packageAttachments = action.payload
      })
      .addCase(getPackageAttachmentsRejected, state => {
        state.packageAttachmentsStatus = 'failed'
      })

      .addCase(getChargesPending, state => {
        state.chargesStatus = 'loading'
      })
      .addCase(getChargesFulfilled, (state, action) => {
        state.chargesStatus = 'succeeded'
        state.charges = action.payload
      })
      .addCase(getChargesRejected, state => {
        state.chargesStatus = 'failed'
      })
  },
})

export default packagesSlice.reducer
